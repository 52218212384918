import React from 'react';


export const mobileNavBarDefault = {
  sidebarIsOpen: false,
  numberOfProductInBasket: 0,
  openPurchasingModal: () => {},
};

export const MobileNavBarContext = React.createContext({
  mobileNavBarDefault,
});

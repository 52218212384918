import React from 'react';

import { MobileNavBarContext, mobileNavBarDefault } from "./src/store/context";

export const wrapRootElement = ({ element }) => {
  return (
    <MobileNavBarContext.Provider value={mobileNavBarDefault}>
      { element }
    </MobileNavBarContext.Provider>
  )
}
